.section-container {
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 100%;
}

.section-image-container {
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-image-container-left {
  justify-content: flex-start;
}

.section-image-container-right {
  justify-content: flex-end;
}

.section-image-container img {
  max-width: 100%;
}

.section-image-container-left img {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.section-image-container-right img {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
}

.section-main-container {
  display: flex;
  align-items: center;
}

h1.section-main-title {
  font-family: "Poppins", sans-serif;
  color: #5a5a5a;
  font-weight: 500;
  font-size: 28px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 20px;
}

h1.section-main-title:after {
  content: '';
  position: absolute;
  top: -35px;
  left: 0;
  width: 100px;
  height: 12px;
  background-color: #5BACE2;
  border-radius: 6px;
}

.section-main-content {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #797979;
}

