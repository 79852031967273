.form_section {
  margin-bottom: 30px;
}
  
.form_section label {
  color: #797979;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  display: block;
}

.form_section input[type="text"],
.form_section input[type="email"],
.form_section select,
.form_section textarea {
  background-color: #FFFFFF;
  border: 0.5px solid #C9C9C9;
  border-radius: 50px;
  width: 100%;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
}

.form_section input[type="text"]:focus,
.form_section input[type="email"]:focus,
.form_section select:focus,
.form_section textarea:focus {
  outline: none;
  border: 1px solid #29CB9A;
}

.form_section select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form_section select::-ms-expand {
  display: none;
}

.form_select_wrapper {
  position: relative;
}

.form_select_wrapper:after {
  content: '\25BE';
  color: #333;
  position: absolute;
  top: 10px;
  right: 15px;
  pointer-events: none;
  font-size: 20px;
}

.form_section select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form_section textarea {
  border-radius: 25px;
  height: 120px;
  resize: none;
}

.form_section input[type="text"]:placeholder,
.form_section input[type="email"]:placeholder,
.form_section textarea:placeholder {
  color: #C4C4C4;
  font-size: 16px;
  line-height: 19px;
}

.form_section input[type="text"].error,
.form_section input[type="email"].error,
.form_section textarea.error {
  border: 1px solid #e34652 !important;
}

.form_section .error_text {
  color: #e34652;
  font-size: 16px;
}