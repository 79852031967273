.form-container {
  position: relative;
}

.form-anchor {
  position: absolute;
  top: -70px;
  left: 0;
}

.form-title h1 {
  font-weight: 600;
  font-size: 28px;
  color: #474747;
}

.form-main-container {
  margin-bottom: 40px;
}

.form-main {
  background-color: #FCFCFC;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 700px;
  width: 100%;
  padding: 20px;
}

.form-response {
  font-size: 16px;
}